import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DealBuilderApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin } from "../services/Permissions";
import DealOverview from "../modules/DealOverview";
import ModuleFunctions from "../modules/ModuleFunctions";

export default function DealCreate(props) {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [data, setData] = useState({ owner_id: props.context.auth.contact, owner_uuid: props.context.auth.id, owner_name: props.context.auth.name, dynamic: false });
  const [errors, setErrors] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [structure, setStructure] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [vats, setVats] = useState(false);
  const [bus, setBus] = useState(false);
  const [salesOpp, setSalesOpp] = useState(false);

  let history = useHistory();

  const create = CanCreate("deal-builder");
  const read = CanRead("deal-builder");
  const edit = CanUpdate("deal-builder");
  const admin = CanModuleAdmin("deal-builder");
  const readAll = CanReadAll("deal-builder");

  const dealChange = (data) => {
      setData(prevState => ({
          ...prevState,
          ...data 
      }));
  };

  const triggerChange = () => {
      setFetchTrigger(fetchTrigger + 1);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const structureChange = (data) => {
      setStructure(data);
  };

  const periodsChange = (data) => {
      setPeriods(data);
  };

  const vatsChange = (data) => {
      setVats(data);
  };

  const businessChange = (data) => {
    setBus(data)
  }

  const salesOppChange = (data) => {
    setSalesOpp(data)
  }

  const handleSave = async () => {  
    setLoading({ status: true, data: "Creating your Deal, Please Wait...." });

    const res = await DealBuilderApi.saveDeal(data);

    if(res.success) {
      setLoading({ status: true, data: "Successfully created Deal" });
      setTimeout(() => {
        history.push({
          pathname: `/deal-builder/edit/${res.data.id}`,
          state: { hasErrors: false }
        });
        setLoading({ status: false });
      }, 3000);
    } else {
        if(res.errors) {
            setErrors(res.errors);
            setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
            setTimeout(() => {
                setLoading({ status: false });
            }, 3000);
        } else {
            setLoading({ status: true, data: res.message });
            setTimeout(() => {
                history.push("/deal-builder/index");
                setLoading({ status: false });
            }, 3000);
        }
    } 
  };

  useEffect(() => {
    ModuleFunctions.getStructure( {structureChange: structureChange} );
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getVatRates({ vatsChange: vatsChange });
    ModuleFunctions.getBusinesses({ change : businessChange });
    ModuleFunctions.getSalesOpps({ salesOppChange : salesOppChange });
  }, [fetchTrigger]);

  if(create && structure && personnel) {
    return (  
        <>
          <AppHeaderComponent
            saveBtn={handleSave}
            context={props.context}
            theme={props.context.theme}
            name="deal-builder"
            subpage="create"
          />

          <div style={{ paddingTop: 1 }}>
            <DealOverview
              context={props.context}
              type='create'
              create={create}
              read={read}
              edit={edit}
              data={data}
              errors={errors}
              personnel={personnel}
              structure={structure}
              salesOpp={salesOpp}
              periods={periods}
              vats={vats}
              bus={bus}
              dealChange={dealChange}
            />
          </div>
        
          <ApiLoaderComponent
            status={loading.status}
            data={loading.data}
          />
        </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background}/>;
  }
}
