import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModuleButton from "../common/ModuleButton";
import { DealBuilderApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent } from "@unity/components";


const SaveWarningDialogBox = (props) => {

    const [requestData, setRequestData] = useState(false);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <>
        <Dialog open={props.open} onClose={props.close} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle style={{ backgroundColor:"red" }}><h4 style={{ color:"white" }}>Warning</h4></DialogTitle>
            <DialogContent >
                <DialogContentText>
                    <br></br>
                    <br></br>
                    <p>You have unsaved changes. Are you sure you want to continue?</p>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ModuleButton
                            //btnIcon="arrow"
                            text="Continue"
                            style={{
                                background: "red",
                                color: "white",
                                marginRight: 10
                            }}
                            tip="Continue without saving"
                            onClick={ () => window.history.back()}
                        /> 

                    <ModuleButton
                        //btnIcon="check"
                        text="Cancel"
                        style={{
                            background: "blue",
                            color: "white",
                            marginRight: 10
                        }}
                        tip="cancel"
                        onClick={props.close}
                    /> 
            </DialogActions>
        </Dialog>

        
        <ApiLoaderComponent
          status={loading.status}
          data={loading.data}
        />
    
        </>
    )
}

export default SaveWarningDialogBox

