import React, { useEffect, useState , useRef } from "react";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, TextField, InputAdornment, CircularProgress } from "@mui/material";
import ModuleFunctions from "./ModuleFunctions";


export default function ProductOverview(props) {

    const [prodCats, setProdCats] = useState(false)
    const [groups, setGroups] = useState(false)
    const [lines, setLines] = useState(false)

    const uneditable = ((props.type === 'view') || !props[props.type] || props.data.retired);

    const findAgentBus = async (agentId) => {
        const person = props.personnel.find(x => x.id == agentId);
        const filtered = props.structure.find( (unit) => unit.value === person.group_uuid );
        if(typeof filtered === "undefined") {
            return "Group not found";
        } else {
            let obj = {};    
            obj[ "sbu_id" ] = filtered.id;
            obj[ "sbu_name" ] = filtered.name;
            props.dealChange( obj );
        }
    }

    const findContactBus = async (contactId) => {
        const person = props.personnel.find(x => x.contact_id == contactId);
        const filtered = props.structure.find( (unit) => unit.value === person.group_uuid );
        if(typeof filtered === "undefined") {
            return "Group not found";
        } else {
            let obj = {};    
            obj[ "sbu_id" ] = filtered.id;
            obj[ "sbu_name" ] = filtered.name;
            props.dealChange( obj );
        }
    }

    const prodCatsChange = (data) => {
        setProdCats(data)
      }
    
      const groupsChange = (data) => {
        setGroups(data)
      }
    
      const linesChange = (data) => {
        setLines(data)
      }
    
    useEffect(() => {
        if(props.type === "create") {
            findAgentBus(props.context.auth.agent_id)
        }
        if(props.type != "create") {
            ModuleFunctions.setCurrentProductStructure({
                data: props.data,
                structure: props.structure,
                prodCatsChange: prodCatsChange,
                groupsChange: groupsChange,
                linesChange: linesChange
              })
        }
        
    }, []);

    return (
        <div className="block">
            <div className="container-fluid">
                <div style={{ width: '100%' }}>
                    <div className="form-row">
                        <div className="form-group col-lg-12">
                            <h4>Product Overview</h4>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group-sm col-lg-8">
                            <TextField
                                label="Name"
                                name="name"
                                type="text"
                                value={props.data.name || ""}
                                size="small"
                                fullWidth
                                error={props.errors && props.errors.name}
                                helperText={props.errors && props.errors.name ? props.errors.name : ""}
                            ></TextField>
                        </div>

                        <div className="form-group-sm col-lg-4">
                            <TextField
                                label="Product Ref"
                                name="product_ref"
                                type="text"
                                value={props.data.product_ref || ""}
                                size="small"
                                fullWidth
                                error={props.errors && props.errors.name}
                                helperText={props.errors && props.errors.name ? props.errors.name : ""}
                            ></TextField>
                        </div>

                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-12">
                            <TextField
                                label="Description"
                                name="description"
                                type="text"
                                value={props.data.description || ""}
                                multiline
                                minRows={2}
                                maxRows={5}
                                error={props.errors && props.errors.notes || false}
                                helperText={props.errors && props.errors.notes || ""}
                                onChange={(e) => uneditable || ModuleFunctions.handleDealChange({ name: e.target.name, value: e.target.value, dealChange: props.dealChange })}
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: uneditable }}
                            ></TextField>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-3">
                            <FormControl error={props.errors && props.errors.prod_group_id || false} fullWidth>
                                <InputLabel>SBU</InputLabel>
                                <Select
                                    label="SBU"
                                    name="sbu_id"
                                    value={props.data.sbu_id || ""}
                                    //onChange={(evt) => handleVersionState(evt)}
                                    inputProps={{ readOnly: true }}
                                    size="small"
                                >
                                    {props.bus && props.bus.map((item, key) => {
                                        return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{props.errors && props.errors.owner_id || ""}</FormHelperText>
                            </FormControl>
                        </div>

                        <div className="form-group-sm col-lg-3">
                            <FormControl error={props.errors && props.errors.prod_group_id || false} fullWidth>
                                <InputLabel>Product Group</InputLabel>
                                <Select
                                    label="Product Group ID"
                                    name="prod_group_id"
                                    value={props.data.prod_group_id || ""}
                                    //onChange={(evt) => handleVersionState(evt)}
                                    inputProps={{ readOnly: true }}
                                    size="small"
                                >
                                    {groups && groups.map((item, key) => {
                                        return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{props.errors && props.errors.owner_id || ""}</FormHelperText>
                            </FormControl>
                        </div>
                        
                        <div className="form-group-sm col-lg-3">
                            <FormControl error={props.errors && props.errors.prod_cat_id || false} fullWidth>
                                <InputLabel>Product Category</InputLabel>
                                <Select
                                    label="Product Category ID"
                                    name="prod_cat_id"
                                    value={props.data.prod_cat_id || ""}
                                    //onChange={(evt) => handleVersionState(evt)}
                                    inputProps={{ readOnly: true }}
                                    size="small"
                                >
                                    {prodCats && prodCats.map((item, key) => {
                                        return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{props.errors && props.errors.owner_id || ""}</FormHelperText>
                            </FormControl>
                        </div>

                        <div className="form-group-sm col-lg-3">
                            <FormControl error={props.errors && props.errors.prod_cat_id || false} fullWidth>
                                <InputLabel>Product Line</InputLabel>
                                <Select
                                    label="Product Line ID"
                                    name="prod_line_id"
                                    value={props.data.prod_line_id || ""}
                                    //onChange={(evt) => handleVersionState(evt)}
                                    inputProps={{ readOnly: true }}
                                    size="small"
                                >
                                    {lines && lines.map((item, key) => {
                                        return <MenuItem key={key} value={item.id}>{item.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText>{props.errors && props.errors.owner_id || ""}</FormHelperText>
                            </FormControl>
                        </div>

                    </div>

                    <div className="form-row">
                        <div className="form-group-sm col-lg-12">
                            <TextField
                                label="Notes"
                                name="notes"
                                type="text"
                                value={props.data.notes || ""}
                                multiline
                                minRows={2}
                                maxRows={5}
                                error={props.errors && props.errors.notes || false}
                                helperText={props.errors && props.errors.notes || ""}
                                onChange={(e) => uneditable || ModuleFunctions.handleDealChange({ name: e.target.name, value: e.target.value, dealChange: props.dealChange })}
                                size="small"
                                fullWidth
                                InputProps={{ readOnly: uneditable }}
                            ></TextField>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
