import React, { useState, useEffect } from "react";
import { ProductsApi, DealBuilderApi , ApiLoaderComponent } from "@unity/components";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ProductDisplayTable from "./ProductDisplayTable";
import ModuleButton from "../common/ModuleButton";
import DataTable from "../common/DataTable";
import ModuleFunctions from "./ModuleFunctions";


const ProductSelectionDialogBox = (props) => {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [pagination, setPagination] = useState(false);// Flag to switch between unpaginated and paginated data!
  const [searchText, setSearchText] = useState('');
  const [productArr, setProductArr] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([]);

  const getProducts = async () => {

    const res = await ProductsApi.getActiveProducts({ read_all: true, pagination: pagination, page: page + 1, per_page: pageSize, search_term: searchText });

    if (res.success) {
      setProductArr(res.data);
      if (pagination) {
        setRowCount(res.meta.total);
      }
    } else {
      // the request has failed, load an empty array!
      setProductArr([]);
    }
  };

  const addRow = (row) => {
    const productsArray = selectedProducts;
    const selectedRow = { ...row };

    selectedRow['deal_id'] = parseInt(props.deal_id);

    productsArray.push(selectedRow);
    setSelectedProducts(productsArray);
  }


  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };
  
  const handleSaveProduct = async (obj) => {
    const res = await DealBuilderApi.saveLiveProduct(obj);
    props.close();
  };

  const handleSaveProductArray = async (array) => {
    setLoading({ status: true, data: "Saving product, please wait..." });
    const unresolvedPromises = array.map(obj => handleSaveProduct(obj)); 

    await Promise.all(unresolvedPromises).then(success => {
      setLoading({ status: true, data: "Succssfully saved product(s)!" });
    }).catch(error => {
      setLoading({ status: true, data: `Unable to delete deal with id: ${error}` });
    });

    ModuleFunctions.getDeal({ id: props.deal_id, dealChange: props.dealChange });

    setTimeout(() => {
      setLoading({ status: false });
      props.close();
    }, 3000);
    props.triggerChange
  };


  useEffect(() => {
    getProducts();
  }, []);


  const columns = [
    { field: "ref", headerName: "Ref", flex: 0.3 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "valid_from", headerName: "Valid From", flex: 0.5 },
    { field: "valid_to", headerName: "Valid To", flex: 0.5 },
    {
      field: "",
      headerName: "Action",
      minWidth: 400,
      renderCell: (cellValues) => {
        const pd = selectedProducts.find(x => x.id === cellValues.row.id);
        if (typeof pd === "undefined") {
          return (
            <div>
              <ModuleButton
                btnIcon="add"
                text="Add"
                style={{
                  background: "blue",
                  color: "white"
                }}
                tip="Add product"
                onClick={() => addRow(cellValues.row)}
              />
            </div>
          );
        } else {
          return null;
        }
        
      },
    }
  ];


  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 300
  };


  return (
    <>
    <Dialog open={props.open} onClose={props.close} fullWidth={true} maxWidth={'xl'} >
      <DialogTitle><h4>Select your products</h4></DialogTitle>
      <DialogContent>
          <DataTable
            columns={columns}
            data={productArr}
            pagination={pagination}
            options={options}
            allowDelete={false}
            handleDeleteArray={false}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
            checkboxSelection
          />
      </DialogContent>
      <DialogActions>
      <ModuleButton
          btnIcon="close"
          text="Cancel"
          style={{
            background: "red",
            color: "white",
          }}
          tip="Cancel"
          onClick={() => props.close()}
        />

        <ModuleButton
          btnIcon="check"
          text="Save"
          style={{
            background: "blue",
            color: "white",
            marginRight: 10
          }}
          tip="Save"
          onClick={() => handleSaveProductArray(selectedProducts)}
        />
      </DialogActions>
    </Dialog>
    
    <ApiLoaderComponent
            status={loading.status}
            data={loading.data}
          />
    </>
  )
}

export default ProductSelectionDialogBox
