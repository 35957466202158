import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { DealBuilderApi, StorageManager } from "@unity/components";
import { Button, Fab, Link, TextField, Tooltip, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dayjs from "dayjs";
import ModuleFunctions from "../ModuleFunctions";

const DEFAULTPERPAGE = 25
const sm = new StorageManager()

export default function ActiveConIndex({ context, bus }) {
  const [searchedText, setSearchedText] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(DEFAULTPERPAGE)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [productTotal, setProductTotal] = useState(0)
  const [personnel, setPersonnel] = useState(false)
  const [salesOpp, setSalesOpp] = useState(false);


  const history = useHistory();

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/deal-config/${path}/${params.id}`,
      state: { ...params }
    });
};

const salesOppChange = (data) => {
  setSalesOpp(data)
}

const columns = [
  { field: "ref", headerName: "Ref#", flex: 0.5 },
  { field: "name", headerName: "Deal Name", flex: 1 },
  {
    field: "sbu_id",
    headerName: "Business Unit",
    minWidth: 200,
    renderCell: (cellValues) => {
      if (cellValues.row.sbu_id) {
        const pc = bus.find(e => cellValues.row.sbu_id === e.id)
        return pc.name;
      } else {
        return null;
      }
    }
  },
  {field: "sales_opp_id", headerName: "Sales Opp Name", minWidth: 200,
  renderCell: (cellValues) => {
    if (cellValues.row.sales_opp_id ) {

      const pc = salesOpp.find(e => cellValues.row.sales_opp_id === e.id)
        return pc.sales_order_ref;
    
    } else {
      return "";
    }
  }

  },
  {
    field: "owner_name", headerName: "Deal Owner", flex: 0.5,
    renderCell: (cellValues) => {
      if (cellValues.row.owner_id && personnel) {
        const un = personnel.find(x => x.contact_id == cellValues.row.owner_id)
        if(un === undefined){
          return "User not found"
        }
        return un.name;
      } else {
        return null
      }
    }
  },
  {
    field: "valid_from",
    headerName: "Valid From",
    flex: 0.5,
    renderCell: (cellValues) => {
      if (cellValues.row.valid_from) {
        return dayjs(cellValues.row.valid_from).format('DD/MM/YYYY')
      } else {
        return <span style={{ color: "red" }}>Valid to date not set</span>
      }
    }
  },
  {
    field: "valid_to", headerName: "Valid To", flex: 0.5,
    renderCell: (cellValues) => {
      if (cellValues.row.valid_to) {
        return dayjs(cellValues.row.valid_to).format('DD/MM/YYYY')
      } else {
        return <span style={{ color: "red" }}>Valid to date not set</span>
      }
    }
  },
  {
    field: "",
    headerName: "Action",
    flex: 0.5,
    renderCell: (cellValues) => {
      return (
        <div>
          <Tooltip title={"View"}>
              <Button
                style={{
                  color: context.theme.sidebar.background, 
                  backgroundColor: "transparent",
                  boxShadow: "none"
                }}
                onClick={(event) => {handleLinkClick(event, cellValues.row, "view")}}
                variant="contained"
              ><VisibilityIcon /></Button>
            </Tooltip>
        </div>
      );
    },
  }
];



  const getData = async (passedPage = page, passedSearch = searchedText, passedPageSize = pageSize) => {
    
    const res = await DealBuilderApi.getResponsibleActiveDeals({
      read_all: context.auth.access['deal-config'].RA,
      pagination: true,
      page: passedPage + 1,
      per_page: passedPageSize,
      search_term: passedSearch
    });

    if (!res.success) return alert("Unable to load data");

    sm.setItem("last-searched-deal-config-active-index", passedSearch);     

    setSearchedText(passedSearch)
    setData(res.data)
    setPageSize(passedPageSize)
    setPage(passedPage)
    setProductTotal(res.meta.total)

  }

  const handlePageSizeChange = async (newSize) => {
    setLoading(true);
    sm.setItem("last-pagesize-deal-config-active-index", newSize); 
    await getData(page, searchedText, newSize)
    setLoading(false);
  }

  const handlePageChange = async (newPage) => {
    setLoading(true);
    sm.setItem("last-page-deal-config-active-index", newPage);
    await getData(newPage, searchedText, pageSize);
    setLoading(false);
  }

  const loadData = async () => {
    setLoading(true)
    const initSearchedText = sm.getItem("last-searched-deal-config-active-index", searchedText) ?? searchedText;
    const initPage = sm.getItem("last-page-deal-config-active-index", page) ?? page;
    let initPageSize = sm.getItem("last-pagesize-deal-config-active-index", pageSize) ?? pageSize;
    await ModuleFunctions.getPersonnel({ personnelChange: setPersonnel });
    await ModuleFunctions.getSalesOpps({ salesOppChange: salesOppChange });


    if( typeof initPageSize === "string" && initPageSize != null )  initPageSize = parseInt(initPageSize);

    await getData(initPage, initSearchedText, initPageSize)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])


  if (loading) return (<h1>Loading...</h1>);

  if (!data || !Array.isArray(data)) return (<h1>No records found</h1>);

  return (
    <>
    <DataGrid
      rows={data}
      columns={columns}
      pageSize={pageSize}
      rowsPerPageOptions={[10, 25, 50, 100]}
      page={page}
      onPageSizeChange={(newSize) => {
        handlePageSizeChange(newSize);
      }}
      onPageChange={(newPage) => {
        handlePageChange(newPage);
      }}
      rowCount={productTotal}
      pagination
      paginationMode="server"
      density="compact"
      getRowId={(param) => param.id}
      autoHeight
      sx={{
        boxShadow: 2,
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: context.theme.sidebar.background,
          color: context.theme.sidebar.font,
          borderRadius: "0px"
        }
      }}

      components={{
        Toolbar: () => (
          <div style={{
            backgroundColor: context.theme.sidebar.background,
            margin: "0px",
            padding: "10px",
            borderTopRightRadius: "5px",
            borderTopLeftRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"

          }}>
            <Typography
              style={{ color: context.theme.sidebar.font, fontWeight: "bold", fontSize: "1.5rem"}}
            >
              Active Deals
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1vw"
              }}
            >
              <TextField 
              style= {{ backgroundColor: "white" }}
              defaultValue={searchedText}
              onBlur={ (e) => {setSearchedText( e.target.value )} }
              placeholder='Search'
              />

              <Tooltip title={"Search"}>
              <Button
                style={{
                  background: context.theme.sidebar.background,
                  color: "white"
                }}
                onClick={() => {getData(page, searchedText, pageSize)}}
                variant="contained"
              ><SearchIcon /></Button>
            </Tooltip>

            <Tooltip title={"Clear search"}>
              <Button
                style={{
                  background: "red",
                  color: "white"
                }}
                onClick={() => {getData(page, "", pageSize)}}
                variant="contained"
              ><ClearIcon /></Button>
            </Tooltip>

            </div>
          </div>
        )
      }}
    />

</>
  )
}