import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { styled } from '@mui/material/styles';
import { CodesApi, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ModuleButton from "../common/ModuleButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import NotificationCapture from "./NotificationCapture";
import ModuleFunctions from "./ModuleFunctions";

export default function Settings({ context }) {
  const [notifs, setNotifs] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [errors, setErrors] = useState(false);
  const [openNotif, setOpenNotif] = useState(false);
  const [dialogData, setDialogData] = useState(false);
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!

  let history = useHistory();

  const dialogChange = (data) => {
    setDialogData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const triggerChange = () => {
      setFetchTrigger(fetchTrigger + 1);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const getData = async () => {
    const res = await CodesApi.getNotifications();

    if(res.success) {
      if(res.data !== null) {
        setNotifs(res.data.optional_fields);
      } else {
        setNotifs([]);
      }
    }
  };

  const handleNotifDelete = async (key) => {
    const newData = notifs;
    newData.splice(key, 1);
    
    const res = await CodesApi.saveNotifications({ notifications: newData });

    if(res.success) {
      triggerChange();
    }
  };

  const handleNotifSubmit = async () => {
    setLoading({ status: true, data: "Saving your settings, Please Wait...." });

    const newData = notifs;
    newData.push(dialogData);
    
    const res = await CodesApi.saveNotifications({ notifications: newData });

    if(res.success) {
        setTimeout(() => {
            triggerChange();
            setOpenNotif(false);
            setDialogData(false);
            setErrors(false);
            setLoading({ status: false });
        });
    } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
            history.push('/deal-builder/index');
            setLoading({ status: false });
        }, 3000);
    }
  };

  const StructureList = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: 5,
    margin: 10
  }));


  useEffect(() => {
    getData();
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
  }, [fetchTrigger]);


  if (notifs) {
    return (
    <>
      <div className="block">
        <div className="container-fluid">
          <div style={{ width: '100%' }}>
            <div className="form-row">
              <div className="form-group col-lg-12">
                <h4>Deal Settings</h4>
              </div>
            </div>

            <Box sx={{ flexGrow: 1, mt: 3, borderRadius: 1, backgroundColor: "lightgray" }}>
              <Grid container spacing={0}>
                  <Grid item xs={12} md={12}>
                      <Typography sx={{ mt: 1 }} align="center" variant="h6" component="div">
                          Admin Action Notification Recipients
                      </Typography>
                      <StructureList>
                          <List dense={true}>
                              {notifs && notifs.map((item, key) => {
                                  return (
                                      <ListItem
                                          secondaryAction={
                                            <Tooltip title="Delete notification">
                                              <IconButton
                                                  edge="end"
                                                  aria-label="delete"
                                                  onClick={() => handleNotifDelete(key)}>
                                                  <DeleteIcon />
                                              </IconButton>
                                            </Tooltip>
                                          }
                                      >
                                          <ListItemAvatar>
                                              <Avatar>
                                              <img
                                                className="profile"
                                                src={
                                                  context.profile
                                                    ? context.profile
                                                    : "https://www.gravatar.com/avatar/94d093eda664addd6e450d7e9881bcad?s=32&d=identicon&r=PG"
                                                }
                                                alt="Profile"
                                              />
                                              </Avatar>
                                          </ListItemAvatar>
                                          <ListItemText
                                              primary={item.name}
                                          />  
                                      </ListItem>
                                  )
                              })}
                              <ListItem>
                                  <ModuleButton
                                      btnIcon="add"
                                      text="add recipient"
                                      style={{
                                          background: context.theme.sidebar.background,
                                          color: "white"
                                      }}
                                      tip="Add a notification recipient"
                                      onClick={() => setOpenNotif(true)}
                                  />
                              </ListItem>
                          </List>
                      </StructureList>
                  </Grid>
              </Grid>
            </Box>

          </div>
        </div>
      </div>

      <NotificationCapture
        title="Notification Recipient Selection"
        info="You can select a person to receive admin action notifications."
        open={openNotif}
        setOpen={setOpenNotif}
        errors={errors}
        setErrors={setErrors}
        dialogData={dialogData}
        setDialog={setDialogData}
        personnel={personnel}
        dialogChange={dialogChange}
        handleSubmit={handleNotifSubmit}
      />

      <ApiLoaderComponent
        status={loading.status}
        data={loading.data}
      />
    </>
    );
  } else {
    return <LoadingComponent />;
  }
}
