import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { AppHeaderComponent, StorageManager, TitleControl } from "@unity/components";
import { CanCreate, CanModuleAdmin } from "../services/Permissions";
import ModuleFunctions from "../modules/ModuleFunctions";
import ModuleTabs from "../common/ModuleTabs";
import ModuleButton from "../common/ModuleButton";
import ActiveIndex from "../modules/ActiveIndex";
import ArchivedIndex from "../modules/ArchivedIndex";
import Settings from "../modules/Settings";
import DraftIndex from "../modules/DraftIndex";

const MAXTAB = 3;
const MINTAB = 0;

export default function Deals(props) {
  const [tab, setTab] = useState(0);
  const [bus, setBus] = useState(false);

  const sm = new StorageManager()
  const tc = new TitleControl(); 
  const history = useHistory();

  const create = CanCreate("deal-builder");
  const admin = CanModuleAdmin("deal-builder");

  const setTitle = () => {
    console.log("triggered")
    tc.setControlTitle((
       <>
       {/* {create && tab !== 3 ?
       <ModuleButton
              text="Add Deal"
              btnIcon="add"
              stylename="primary"
              style={
                {
                  background: props.context.theme.top_menu.button_background,
                  color: props.context.theme.top_menu.button_font,
                }
              }
              onClick={() => {history.push({
                pathname: "/deal-builder/create",}); }
              }
            />
          :
          <></>
          } */}
       </>
    ), "DEAL BUILDER")
  }

  const busChange = (data) => {
    setBus(data);
  };
  
  const handleChange = async (event, newValue) => {
    setTab(newValue);
  };

  const tabs = [
    { icon: "update", label: "draft" },
    { icon: "build", label: "active" },
    { icon: "archive", label: "archived" },
  ];

  if (admin) {
    tabs.push(
      { icon: "settings", label: "module settings" }
    )
  };

  useEffect(() => {
    setTitle()
    ModuleFunctions.getBusinesses({ change: busChange });
    let t = parseInt( sm.getItem( "last-viewed-deal-builder-tab" , 0 ) )
    if( isNaN(t) ) t = 0;

    if( t >= MINTAB && t <= MAXTAB || t != NaN ) setTab(t);
    
  }, []);

  if(sm.checkGrant()){
    useEffect(() => {
      //setTitle()
        sm.setItem("last-viewed-deal-builder-tab", tab);
    }, [tab]);
}

  return (
    <>
      {create && tab !== 3 ? (
        <AppHeaderComponent
          addNew={() => <Link to={"/deal-builder/create"}>
            <ModuleButton
              text="Add Deal"
              btnIcon="add"
              stylename="primary"
              style={
                {
                  background: props.context.theme.top_menu.button_background,
                  color: props.context.theme.top_menu.button_font,
                }
              }
            />
          </Link>}
          context={props.context}
          theme={props.context.theme}
          name={"deal-builder"}
          spacer={true}
        />
      ) : (
        <AppHeaderComponent
          context={props.context}
          theme={props.context.theme}
          name={"deal-builder"}
          spacer={true}
        />
      )}
      
      <ModuleTabs tabs={tabs} value={tab} setValue={setTab} handleChange={handleChange} />
      <div style={{ paddingTop: 86 }}>
      {tab === 0 && 
          <DraftIndex
            context={props.context}
            bus={bus}
          />}
        {tab === 1 && 
          <ActiveIndex
            context={props.context}
            bus={bus}
          />}
        {tab === 2 && 
          <ArchivedIndex
            context={props.context}
            bus={bus}
          />}


        {tab === 3 &&
          <Settings context={props.context} />}
      </div>
    </>
  );
}
