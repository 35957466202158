import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ProductsApi, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import { FormControl, FormHelperText, TextField, InputLabel, Select, MenuItem } from "@mui/material";
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import ModuleButton from "../common/ModuleButton";
import ModuleFunctions from "./ModuleFunctions";

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
    const {
        classes,
        className,
        label,
        nodeId,
        icon: iconProp,
        expansionIcon,
        displayIcon,
        model,
        setOpenModel,
        getModel,
        handleChange
    } = props;
  
    const {
        disabled,
        expanded,
        selected,
        focused,
        handleExpansion,
        handleSelection,
        preventSelection,
    } = useTreeItem(nodeId);
  
    const icon = iconProp || expansionIcon || displayIcon;
  
    const handleMouseDown = (event) => {
        preventSelection(event);
    };
  
    const handleExpansionClick = (event) => {
        handleExpansion(event);
    };
  
    const handleSelectionClick = (model) => {
        handleSelection();
        handleChange(model);
        // setOpenModel(model);
        // getModel(model);
    };
  
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
            className={clsx(className, classes.root, {
                [classes.expanded]: expanded,
                [classes.selected]: selected,
                [classes.focused]: focused,
                [classes.disabled]: disabled,
            })}
            onMouseDown={handleMouseDown}
            ref={ref}
        >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleExpansionClick} className={classes.iconContainer}>
                {icon}
            </div>
            <Typography
                onClick={() => handleSelectionClick(model)}
                component="div"
                className={classes.label}
            >
                {label}
            </Typography>
        </div>
    );
});
  
CustomContent.propTypes = {
    /**
     * Override or extend the styles applied to the component.
     */
    classes: PropTypes.object.isRequired,
    /**
     * className applied to the root element.
     */
    className: PropTypes.string,
    /**
     * The icon to display next to the tree node's label. Either a parent or end icon.
     */
    displayIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label. Either an expansion or collapse icon.
     */
    expansionIcon: PropTypes.node,
    /**
     * The icon to display next to the tree node's label.
     */
    icon: PropTypes.node,
    /**
     * The tree node label.
     */
    label: PropTypes.node,
    /**
     * The id of the node.
     */
    nodeId: PropTypes.string.isRequired,
};
  
const CustomTreeItem = (props) => {
    return (
        <TreeItem
            {...props}
            ContentComponent={CustomContent}
            ContentProps={{
                model: props.model,
                setOpenModel: props.setOpenModel,
                getModel: props.getModel,
                handleChange: props.handleChange
            }}
        />
    );
};


export default function ProductStructure({ context, create, read, edit, admin, readAll, modelInfo, updateLine, dialogChange, editMode, updateTree }) {
    const [structure, setStructure] = useState(false);
    const [openModel, setOpenModel] = useState(false);
    const [loading, setLoading] = useState({ status: false });

    
    modelInfo(openModel)


    let counter = 96;// 97 to 121 are ASCII a to z --> character to add to the id in the tree to give some difference as the id's may be the same and this would cause the render to fail!


    const structureChange = (data) => {
        setStructure(data);
    };

    const modelChange = (data) => {
        setOpenModel(prevState => ({
            ...prevState,
            ...data
        }));
    };

    const getModel = async (model) => {
        if (model.id && model.model_name) {
            const res = await ProductsApi[`get${model.model_name}`](model.id);
            if(res.success) {
                modelChange(res.data);
            }
        }
    };

    const handleChange = (model) => {

        const obj = {
            level_type: (model.fixed_name != undefined ? model.fixed_name : model.model_name),
            level_id: model.id,
            level_name: model.name
        };

        dialogChange(obj);
        if(editMode){
            updateTree(obj)
        }
    };


    //---------------------------------------------------------------------------------------------------------------------------------------
    // All the functions in here relate to rendering the Tree
    
    const getChildName = () => {
        const words = openModel.children_array.split('_');
        const word = words[1].slice(0, -1);
        return word[0].toUpperCase() + word.substring(1);
    };

    const handleAddChildModel = () => {
        const newOpen = {
            sbu_id: openModel.type_id,
            prod_cat_id: openModel.prod_cat_id ? openModel.prod_cat_id : openModel.id,
            group_id: openModel.prod_cat_id ? openModel.id : null,
            model_name: getChildName(),// to display model name
            changed: true// so the save button is displayed
        };
        setOpenModel(newOpen);
    };

    // used to render the first layer if it is an array
    const renderTree = (struct) => (
        Array.isArray(struct)
            ? struct.map((bu) => renderChildren(bu))
            : null
    );

    // used to render an individual node and it's children recursively
    // NB: it is important that the nodes have a field on named 'children_tag' to tell the function what the name of the next layers array is.
    //     it is also important that all of the 'keys' & 'nodeId' are unique.
    const renderChildren = (parent) => {
        // update the counter
        if (counter === 122) {
            counter = 97;
        } else {
            counter++;
        }

        return (
            <CustomTreeItem
                key={String.fromCharCode(counter)+'_'+parent.id+'_'+parent.children_array}
                nodeId={String.fromCharCode(counter)+'_'+parent.id+'_'+parent.children_array}
                label={parent.name}
                model={parent}// added the model to pass through when selected
                setOpenModel={setOpenModel}
                getModel={getModel}
                handleChange={handleChange}
            >
                {Array.isArray(parent[parent.children_array])
                    ? parent[parent.children_array].map((node) => renderChildren(node))
                    : null}
            </CustomTreeItem>
        );
    };
    //-----------------------------------------------------------------------------------------------------------------------------------------
    

    useEffect(() => {
        ModuleFunctions.getStructure({ structureChange: structureChange });
        if(openModel) {
            getModel(openModel);
        }
    }, []);



    if (structure) {
        return (
            <>
                <div className="block">
                    <div className="container-fluid">
                        <div style={{ width: '100%' }}>
                            <div className="form-row">
                                <div className="form-group col-lg-12">
                                    <h4>Product Structure</h4>
                                </div>
                            </div>

                            <TreeView
                                aria-label="rich object"
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                sx={{ height: 'auto', flexGrow: 1, maxWidth: '100%', overflowY: 'auto' }}
                            >
                                {renderTree(structure)}
                            </TreeView>
                        </div>
                    </div>
                </div>

                {/* {openModel && (
                    <div className="block">
                        <div className="container-fluid">
                            <div style={{ width: '100%' }}>
                                <div className="form-row">
                                    <div className="form-group col-lg-8">
                                        <h4>{openModel.model_name ? ('Product '+openModel.model_name) : openModel.fixed_name}</h4>
                                    </div>

                                    <div className="form-group col-lg-4">
                                        {admin && !openModel.fixed_children ? (
                                            openModel.changed ? (
                                                <>
                                                    <ModuleButton
                                                        btnIcon="check"
                                                        text="save"
                                                        style={{
                                                            background: "green",
                                                            color: "white",
                                                            marginRight: 10
                                                        }}
                                                        onClick={() => handleSaveModel()}
                                                    />
                                                    <ModuleButton
                                                        btnIcon="close"
                                                        text="cancel"
                                                        style={{
                                                            background: "red",
                                                            color: "white"
                                                        }}
                                                        onClick={() => setOpenModel(false)}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    {openModel.children_array ? (
                                                        <ModuleButton
                                                            btnIcon="add"
                                                            text={'Add Product ' + getChildName()}
                                                            style={{
                                                                background: context.theme.sidebar.background,
                                                                color: "white",
                                                                marginRight: 10
                                                            }}
                                                            onClick={() => handleAddChildModel()}
                                                        />
                                                    ) : (
                                                        <></>
                                                    )}
                                                    {openModel.model_name &&
                                                        (!openModel.children_array || openModel[openModel.children_array].length === 0) &&
                                                        <ModuleButton
                                                            btnIcon="delete"
                                                            text="delete"
                                                            style={{
                                                                background: "red",
                                                                color: "white"
                                                            }}
                                                            onClick={() => handleDeleteModel()}
                                                        />
                                                    }
                                                </>
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>

            
                            </div>
                        </div>
                    </div>
                )} */}

                <ApiLoaderComponent
                    status={loading.status}
                    data={loading.data}
                />
            </>
        );
    } else {
        return <LoadingComponent color={context.theme.sidebar.background} />;
    }
}