import React from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import AuthContext from "./services/AuthContext";
import Deals from "./pages/index";
import DealView from "./pages/view";
import DealEdit from "./pages/edit";
import DealCreate from "./pages/create";
import ProductPage from "./pages/product";
import DealsConfigIndex from "./pages/deal-config/index";
import ConfigView from "./pages/deal-config/ConfigView";
import ConfigProduct from "./pages/deal-config/ConfigProduct";
import { CookieRequestComponent } from "@unity/components";


export default function Root(props) {
  
  let context = { ...props };

  return (
    <AuthContext.Provider value={{ ...props }}>
      <CookieRequestComponent />

      <Router>
        <Route
          render={({ location }) => (
            <TransitionGroup className="transition-group">
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <section className="route-section">
                  <Switch location={location}>
                    <Route path="/location" exact component={Location} /> 

                    {props.auth.access["deal-builder"]["A"] &&
                      props.auth.access["deal-builder"]["R"] && (
                        <Route
                          path="/deal-builder/index"
                          exact
                          component={(route) => (
                            <Deals
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["deal-builder"]["A"] &&
                      props.auth.access["deal-builder"]["R"] && (
                        <Route
                          path="/deal-builder/view/:id"
                          exact
                          component={(route) => (
                            <DealView
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["deal-builder"]["A"] &&
                      props.auth.access["deal-builder"]["C"] && (
                        <Route
                          path="/deal-builder/create"
                          exact
                          component={(route) => (
                            <DealCreate
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}
                    {props.auth.access["deal-builder"]["A"] &&
                      props.auth.access["deal-builder"]["U"] && (
                        <Route
                          path="/deal-builder/edit/:id"
                          exact
                          component={(route) => (
                            <DealEdit
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                      {props.auth.access["deal-builder"]["A"] &&
                      props.auth.access["deal-builder"]["R"] && (
                        <Route
                          path="/deal-builder/product/:id"
                          exact
                          component={(route) => (
                            <ProductPage
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                      {/* Deal-config */}

                      {props.auth.access["deal-config"]["A"] &&
                      props.auth.access["deal-config"]["R"] && (
                        <Route
                          path="/deal-config/index"
                          exact
                          component={(route) => (
                            <DealsConfigIndex
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["deal-config"]["A"] &&
                      props.auth.access["deal-config"]["R"] && (
                        <Route
                          path="/deal-config/view/:id"
                          exact
                          component={(route) => (
                            <ConfigView
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                    {props.auth.access["deal-config"]["A"] &&
                      props.auth.access["deal-config"]["R"] && (
                        <Route
                          path="/deal-config/product/:id"
                          exact
                          component={(route) => (
                            <ConfigProduct
                              route={route}
                              context={context}
                            />
                          )}
                        />
                      )}

                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          )}
        />
      </Router>
    </AuthContext.Provider>
  );
}
