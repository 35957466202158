import React, { useState, useEffect } from "react";
import { Snackbar, TextField } from "@mui/material";



const ProductLineOverview = (props) => {

    return (

<div className="block">

                <div className="container-fluid">
                  <div style={{ width: '100%' }}>
                    <div className="form-row">
                      <div className="form-group col-lg-12">
                        <h4>Product Line Overview</h4>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className={ props.fullBoxInfo.dynamic ? "form-group-sm col-lg-12" : "form-group-sm col-lg-6"}>
                        <TextField
                          label="Product Name"
                          name="Product name"
                          type="text"
                          defaultValue={props.fullBoxInfo.description}
                          size="small"
                          fullWidth
                          InputProps={{ readOnly: props.uneditable }}
                        ></TextField>
                      </div>

                      {props.fullBoxInfo.dynamic ?
                      null :
                      <div className="form-group-sm col-lg-3">
                        <TextField
                          label="Cost"
                          name="Cost"
                          type="text"
                          defaultValue={props.fullBoxInfo.unit_price}
                          size="small"
                          fullWidth
                          InputProps={{ readOnly: props.uneditable }}
                        ></TextField>
                      </div>}

                      { props.fullBoxInfo.dynamic ? 
                      null :
                      <div className="form-group-sm col-lg-3">
                        <TextField
                          label="Minimum Term"
                          name="Minimum Term"
                          type="text"
                          defaultValue={props.fullBoxInfo.min_term}
                          size="small"
                          fullWidth
                          InputProps={{ readOnly: props.uneditable }}
                        ></TextField>
                      </div> }

                    </div>

                    <div className="form-row">

                    { props.fullBoxInfo.dynamic ? 
                      null :
                      <div className="form-group-sm col-lg-4">
                        <TextField
                          label="Price"
                          name="Price"
                          type="text"
                          defaultValue={props.fullBoxInfo.price}
                          onChange={(evt) => handleChange(evt)}
                          size="small"
                          fullWidth
                          //InputProps={{ readOnly: props.uneditable }}
                          InputProps={{ readOnly: true }}
                        ></TextField>
                      </div> }

                      <div className="form-group-sm col-lg-2">
                        <TextField
                          label="Quantity"
                          name="Quantity"
                          type="text"
                          defaultValue={props.fullBoxInfo.quantity}
                          size="small"
                          fullWidth
                          InputProps={{ readOnly: props.uneditable }}
                        ></TextField>
                      </div>

                      <div className={ props.fullBoxInfo.dynamic ? "form-group-sm col-lg-5" : "form-group-sm col-lg-3" }>
                        <TextField
                          label="Charge Code ID"
                          name="Charge Code ID"
                          type="text"
                          defaultValue={
                            props.getChargeData(props.fullBoxInfo.charge_code_id)
                          }
                          size="small"
                          fullWidth
                          InputProps={{ readOnly: props.uneditable }}
                        ></TextField>
                      </div>

                      <div className={ props.fullBoxInfo.dynamic ? "form-group-sm col-lg-5" : "form-group-sm col-lg-3" }>
                        <TextField
                          label="Periodic Code ID"
                          name="Periodic Code ID"
                          type="text"
                          defaultValue={props.getPeriodicData(props.fullBoxInfo.periodic_code_id)}
                          size="small"
                          fullWidth
                          InputProps={{ readOnly: props.uneditable }}
                        ></TextField>
                      </div>


                    </div>

                  </div>
                </div>
              </div>
                            )
}

                            export default ProductLineOverview