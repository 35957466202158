import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ModuleFunctions from "./ModuleFunctions";
import DataTable from "../common/DataTable";
import ModuleButton from "../common/ModuleButton";
import { CanDelete } from "../services/Permissions";
import { DealBuilderApi, ApiLoaderComponent } from "@unity/components";
import { Tooltip } from "@mui/material";
import { Fade } from '@mui/material';

const PersonDisplayTable = (props) => {

  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState({ status: false });
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [pagination, setPagination] = useState(false);// Flag to switch between unpaginated and paginated data!
  const [searchText, setSearchText] = useState('');

  let history = useHistory();

  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  // The following functions are both required for the correct
  // async use of deleting multiple rows!
  // -----------------------------------------------------------------------------------
  const handleDelete = async (id) => {
    await DealBuilderApi.deleteTeamMember(id);
  };

  const handleDeleteArray = async (array) => {

    setLoading({ status: true, data: "Removing team member, please wait..." });
    const unresolvedPromises = array.map(id => handleDelete(id));

    await Promise.all(unresolvedPromises).then(success => {
      setLoading({ status: true, data: "Succssfully removed team member" });
      props.triggerChange()
    }).catch(error => {
      setLoading({ status: true, data: `Unable to remove team member with id: ${error}` });
    });

    setTimeout(() => {
      setLoading({ status: false });
    }, 3000);
  };
  // -----------------------------------------------------------------------------------

  const columns = [
    {
      field: "responsible_id", headerName: "Person", flex: 0.3,
      renderCell: (cellValues) => {
        const res = props.personnel.find(x => x.contact_id == cellValues.row.responsible_id)
        if(typeof res === "undefined"){
          return "User not found"
        }
        return res.name
      }
    }
    ,
    { field: "instructions", headerName: "Note", flex: 1,
    renderCell: (cellValues) => {
      if(cellValues.row.instructions){
        return( <Tooltip title={cellValues.row.instructions} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} onClick={() => props.openInstructionsDialogBox(cellValues.row)}> 
          <span>{cellValues.row.instructions}</span>
         </Tooltip>)} 
        else {
          return ""
        }
    }
    },
    {
      field: "level_name",
      headerName: "Level name",
      minWidth: 200,
      tip: "Test v2"
    },
    {
      field: "level_type", headerName: "Level type", flex: 0.5,
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {
        return (
          props.ownerPermission ?
            <div>
              <ModuleButton
                btnIcon="edit"
                style={{ backgroundColor: "orange" }}
                onClick={() => props.editPersonSelectionDialogBox(cellValues.row)}
                tip = "Edit"
              />
            </div> : null
        );
      },
    }
  ];


  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 300
  };


  return (
    <div className="block">
      <div className="container-fluid">
        <div style={{ width: '100%' }}>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h4>Team Members</h4>
            {props.ownerPermission ?
              <ModuleButton
                text="Assign Person"
                btnIcon="groupAdd"
                stylename="primary"
                style={{ backgroundColor: "blue", color: "white" }}
                onClick={() => props.openDialog({ deal_id: parseInt(props.deal_id) })}
              />
              : null
            }
          </div>

          <div style={{ marginTop: "10px" }}></div>

          <DataTable
            columns={columns}
            data={Array.from(props.data)}
            pagination={pagination}
            options={options}
            allowDelete={props.ownerPermission ? CanDelete("deal-builder") : false}
            handleDeleteArray={handleDeleteArray}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
          />

          <ApiLoaderComponent
            status={loading.status}
            data={loading.data}
          />

        </div>
      </div>
    </div>

  )
}

export default PersonDisplayTable;