import React, { useState, useEffect } from "react";
import ModuleButton from "../common/ModuleButton";
import Dialog from '@mui/material/Dialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { DocumentVaultAPI} from "@unity/components";

const OptionalDocsDialogBox = (props) => {

    const fileIcon = (fileType) => {

        const shortFileType = fileType.slice(-12) //This keeps the last 12 characters of the fiel to ensure a document named the as the file type will still show the correct icon

        if( shortFileType.includes("pdf")){
            return <div style={ {height:"45px", width:"45px", backgroundColor:"red"}}> <span style={{color:"white"}}><strong>PDF</strong></span></div>
        } else if( shortFileType.includes("sheet")){
            return <div style={ {height:"45px", width:"45px", backgroundColor:"#118144"}}> <span style={{color:"white"}}><strong>Excel</strong></span></div>
        } else if( shortFileType.includes("presentation")){
            return <div style={ {height:"45px", width:"45px", backgroundColor:"orange"}}> <span style={{color:"white"}}><strong>PowerP</strong></span></div>
        } else if( shortFileType.includes("document")){
            return <div style={ {height:"45px", width:"45px", backgroundColor:"#1a60c2"}}> <span style={{color:"white"}}><strong>Word</strong></span></div>
        }  else if( shortFileType.includes("jpeg")){
            return <div style={ {height:"45px", width:"45px", backgroundColor:"gray"}}> <span style={{color:"white"}}><strong>JPEG</strong></span></div>
        } else if( shortFileType.includes("png")){
            return <div style={ {height:"45px", width:"45px", backgroundColor:"gray"}}> <span style={{color:"white"}}><strong>PNG</strong></span></div>
        }
        else{
            return fileType
        }
    }

    const handleNewDocumentTab = async (vault_id) => {
        const res = await DocumentVaultAPI.generateTempLink(vault_id.id)
        window.open( res.data, '_blank');
    }

    function byteConverter( bytes, decimals, only) {
        const K_UNIT = 1024;
        const SIZES = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    
        if(bytes== 0) return "0 Byte";
      
        if(only==="MB") return (bytes / (K_UNIT*K_UNIT)).toFixed(decimals) + " MB" ;
      
        let i = Math.floor(Math.log(bytes) / Math.log(K_UNIT));
        let resp = parseFloat((bytes / Math.pow(K_UNIT, i)).toFixed(decimals)) + " " + SIZES[i];
      
        return resp;
    }
    

    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            fullWidth={true}
            maxWidth="lg"
        >
            <DialogTitle style={{ backgroundColor: "blue" , color:"white"}}><h4>Supporting Documents</h4></DialogTitle>
        <DialogContent>
            
                <>
                    <div className="block">
                        <div className="container-fluid">
                            <div style={{ width: '100%' }}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>name</TableCell>
                                                <TableCell>File Type</TableCell>
                                                <TableCell>File Size</TableCell>
                                                <TableCell>Upload Date</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {props.data && props.data.map((row, key) => ( 
                                                <TableRow
                                                    //key={row.description}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {fileIcon(row.raw_type)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {byteConverter(row.size)}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.created_at}
                                                    </TableCell>

                                                    <TableCell component="th" scope="row">
                                                        <ModuleButton
                                                            btnIcon="visibility"
                                                            style={{ backgroundColor: "#1976d2" }}
                                                            onClick={() => handleNewDocumentTab( row )}
                                                            tip="View"
                                                        />

                                                    { props.type === "builder" ? 
                                                    null : 
                                                        <ModuleButton
                                                            btnIcon="delete"
                                                            style={{ backgroundColor: "red" , marginLeft:"10px" }}
                                                            onClick={() => props.deleteOptionalDocument( key )}
                                                            tip="Delete"
                                                        />
                                                    }
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                            </div></div></div>
                </> 
            </DialogContent>

            <DialogActions>
                <Button onClick={props.close}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
export default OptionalDocsDialogBox