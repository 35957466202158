import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState, useEffect } from "react";


const DisplayInstructionsDialogBox = (props) => {
    
    return (
        
        <Dialog
            open={props.open}
            onClose={props.close}
            fullWidth={true}
            maxWidth="md"
        >

        <DialogTitle style={{ backgroundColor: "blue" , color:"white"}}>
            <h4>Note</h4>
        </DialogTitle>
    
        <DialogContent>
                <div className="block">
                    <div className="container-fluid">
                        <div style={{ width: '100%' }}>
                            <span>{props.instructions || ""}</span>
                        </div>
                    </div>
                </div>
        </DialogContent>

        <DialogActions>
            <Button onClick={props.close}>Close</Button>
        </DialogActions>
    </Dialog>
                    )
}

export default DisplayInstructionsDialogBox