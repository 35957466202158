import React, { useEffect, useState } from "react";
import { DataGrid } from '@mui/x-data-grid';
import { DealBuilderApi, StorageManager } from "@unity/components";
import { Button, Fab, Link, TextField, Tooltip, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from "dayjs";
import WarningCheck from "../common/WarningCheck";
import ModuleFunctions from "./ModuleFunctions";
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const DEFAULTPERPAGE = 25
const sm = new StorageManager()

export default function ActiveIndex({ context, bus }) {
  const [searchedText, setSearchedText] = useState('')
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(DEFAULTPERPAGE)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [productTotal, setProductTotal] = useState(0)
  const [warning, setWarning] = useState(false);
  const [warningData, setWarningData] = useState(false);
  const [personnel, setPersonnel] = useState(false)

  const history = useHistory();

  const handleLinkClick = (event, params, path) => {
    event.preventDefault();
    history.push({
      pathname: `/deal-builder/${path}/${params.id}`,
      state: { ...params }
    });
  };

  const handleArchive = async (id) => {
   
    setLoading(true);
    setWarning(false);
    setWarningData(false);

    const res = await DealsApi.dealArchive(id, {});

    if (res.success) {
      setLoading(false);
      setReplaceLog(false);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
    loadData()
  };

  // const handleArchive = async (id) => {
  //   setLoading({ status: true, data: 'Archiving deal, please wait...' });
  //   setWarning(false);
  //   setWarningData(false);

  //   const res = await DealsApi.dealArchive(id, {});

  //   if (res.success) {
  //     setLoading({ status: false });
  //     setReplaceLog(false);
  //   } else {
  //     setLoading({ status: true, data: res.message });
  //     setTimeout(() => {
  //       setLoading({ status: false });
  //     }, 3000);
  //   }
  //   setFetchTrigger(fetchTrigger + 1);
  // };


  const handleVersionUpdate = async (obj) => {

    setLoading(true);
    const res = await DealBuilderApi.updateVersion(obj.id , obj);

    if (res.success) {
      setLoading(true);
      loadData()
      setTimeout(() => {
        setLoading(false);
      }, 3000);

      history.push({
        pathname: `/deal-builder/edit/${res.data.id}`,
        state: { ...res.data }
      });

    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } else {
        setLoading(true);
        setTimeout(() => {
          history.push("/deal-builder/index");
          setLoading(false);
        }, 3000);
      }
    }
  };


  const columns = [
    { field: "ref", headerName: "Ref#", flex: 0.5 },
    { field: "name", headerName: "Deal Name", flex: 1 },
    {
      field: "sbu_id",
      headerName: "Business Unit",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.sbu_id) {
          const pc = bus.find(e => cellValues.row.sbu_id === e.id)
          return pc.name;
        } else {
          return null;
        }
      }
    },
    {
      field: "owner_name", headerName: "Deal Owner", flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.owner_id && personnel) {
          const un = personnel.find(x => x.contact_id == cellValues.row.owner_id)
          if(un === undefined){
            return "User not found"
          } 
          return un.name;
        } else {
          return null
        }
      }
    },
    {
      field: "valid_from",
      headerName: "Valid From",
      minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_from) {
          return dayjs(cellValues.row.valid_from).format('DD/MM/YYYY')
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>
        }
      }
    },
    {
      field: "valid_to", headerName: "Valid To", flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_to) {
          return dayjs(cellValues.row.valid_to).format('DD/MM/YYYY')
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>
        }
      }
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {
        return (
          <div>
            <Tooltip title={"View"}>
                <Button
                  style={{
                    color: context.theme.sidebar.background,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  }}
                  onClick={(event) => { handleLinkClick(event, cellValues.row, "edit") }}
                  variant="contained"
                ><VisibilityIcon /></Button>
              </Tooltip>

            { context.auth.access["deal-builder"].A && context.auth.access["deal-builder"].U &&

              <Tooltip title={"Create a new version of the current deal."}>
                <Button
                  style={{
                    color: context.theme.sidebar.background,
                    backgroundColor: "transparent",
                    boxShadow: "none"
                  }}
                  onClick={() => { handleVersionUpdate(cellValues.row) }}
                  variant="contained"
                ><ContentCopyIcon /></Button>
              </Tooltip>
            }
          </div>
        );
      },
    }
  ];



  const getData = async (passedPage = page, passedSearch = searchedText, passedPageSize = pageSize) => {

    const res = await DealBuilderApi.getActiveDeals({
      read_all: context.auth.access['deal-builder'].RA,
      pagination: true,
      page: passedPage + 1,
      per_page: passedPageSize,
      search_term: passedSearch
    });

    if (!res.success) return alert("Unable to load data");

    sm.setItem("last-searched-deal-builder-active-index", passedSearch);

    setSearchedText(passedSearch)
    setData(res.data)
    setPageSize(passedPageSize)
    setPage(passedPage)
    setProductTotal(res.meta.total)

  }

  const handlePageSizeChange = async (newSize) => {
    setLoading(true);
    sm.setItem("last-pagesize-deal-builder-active-index", newSize);
    await getData(page, searchedText, newSize)
    setLoading(false);
  }

  const handlePageChange = async (newPage) => {
    setLoading(true);
    sm.setItem("last-page-deal-builder-active-index", newPage);
    await getData(newPage, searchedText, pageSize);
    setLoading(false);
  }

  const loadData = async () => {
    setLoading(true)
    const initSearchedText = sm.getItem("last-searched-deal-builder-active-index", searchedText) ?? searchedText;
    const initPage = sm.getItem("last-page-deal-builder-active-index", page) ?? page;
    let initPageSize = sm.getItem("last-pagesize-deal-builder-active-index", pageSize) ?? pageSize;
    await ModuleFunctions.getPersonnel({ personnelChange: setPersonnel });

    if (typeof initPageSize === "string" && initPageSize != null) initPageSize = parseInt(initPageSize);

    await getData(initPage, initSearchedText, initPageSize)
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [])


  if (loading) return (<h1>Loading...</h1>);

  if (!data || !Array.isArray(data)) return (<h1>No records found</h1>);

  return (
    <>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onPageSizeChange={(newSize) => {
          handlePageSizeChange(newSize);
        }}
        onPageChange={(newPage) => {
          handlePageChange(newPage);
        }}
        rowCount={productTotal}
        pagination
        paginationMode="server"
        density="compact"
        getRowId={(param) => param.id}
        autoHeight
        sx={{
          boxShadow: 2,
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: context.theme.sidebar.background,
            color: context.theme.sidebar.font,
            borderRadius: "0px"
          }
        }}

        components={{
          Toolbar: () => (
            <div style={{
              backgroundColor: context.theme.sidebar.background,
              margin: "0px",
              padding: "10px",
              borderTopRightRadius: "5px",
              borderTopLeftRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"

            }}>
              <Typography
                style={{ color: context.theme.sidebar.font, fontWeight: "bold", fontSize: "1.5rem" }}
              >
                Active Deals
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "1vw"
                }}
              >
                <TextField
                  style={{ backgroundColor: "white" }}
                  defaultValue={searchedText}
                  onBlur={(e) => { setSearchedText(e.target.value) }}
                  placeholder='Search'
                />

                <Tooltip title={"Search"}>
                  <Button
                    style={{
                      background: context.theme.sidebar.background,
                      color: "white"
                    }}
                    onClick={() => { getData(page, searchedText, pageSize) }}
                    variant="contained"
                  ><SearchIcon /></Button>
                </Tooltip>

                <Tooltip title={"Clear search"}>
                  <Button
                    style={{
                      background: "red",
                      color: "white"
                    }}
                    onClick={() => { getData(page, "", pageSize) }}
                    variant="contained"
                  ><ClearIcon /></Button>
                </Tooltip>

              </div>
            </div>
          )
        }}
      />

        <WarningCheck
          open={warning}
          setOpen={setWarning}
          dialogData={warningData}
          setDialogData={setWarningData}
          handleSubmit={handleArchive}
        />


    </>
  )
}