import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "../../common/DataTable";
import { CanDelete, CanUpdate } from "../../services/Permissions";
import ModuleButton from "../../common/ModuleButton";
import { DealBuilderApi, ApiLoaderComponent } from "@unity/components";
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";


const ProductConfigTable = (props) => {

  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);// The total number of rows available from the search!
  const [pagination, setPagination] = useState(false);// Flag to switch between unpaginated and paginated data!
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState({ status: false });
  const [errors, setErrors] = useState(false)

  const edit = CanUpdate("deal-config");


  const handleApproveProduct = async (product) => {
    setLoading({ status: true, data: "Updating your Deal, Please Wait...." });
    const res = await DealBuilderApi.liveProductApprove(product.id, product);

    if (res.success) {
      //dealChange({ changed: false });
      setLoading({ status: false });
      props.triggerChange()
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  const handleProductCancel = async (product) => {
    setLoading({ status: true, data: "Updating your Deal, Please Wait...." });
    const res = await DealBuilderApi.liveProductCancel(product.id, product);

    if (res.success) {
      //dealChange({ changed: false });
      props.triggerChange()
      setLoading({ status: false });
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          history.push("/deal-builder/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };

  let history = useHistory();

  const handlePageChange = (page) => {
    setPage(page);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const handleSizeChange = (size) => {
    setPageSize(size);

    if (pagination) {
      setFetchTrigger(fetchTrigger + 1);
    }
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const columns = [
    { field: "product_ref", headerName: "Ref", flex: 0.3 },
    { field: "name", headerName: "Deal Name", flex: 1 },
    {
      field: "valid_from", headerName: "Valid From", minWidth: 200,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_from) {
          return dayjs(cellValues.row.valid_from).format('DD/MM/YYYY')
        } else {
          return <span style={{ color: "red" }}>Valid from date not set</span>
        }
      }
    }, 
    {
      field: "valid_to", headerName: "Valid To", flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.valid_to) {
          return dayjs(cellValues.row.valid_to).format('DD/MM/YYYY')
        } else {
          return <span style={{ color: "red" }}>Valid to date not set</span>
        }
      }
    },
    {
      field: "responsible_id", headerName: "Product owner", flex: 0.5,
      renderCell: (cellValues) => {
        if (cellValues.row.responsible_id && props.personnel) {
          const un = props.personnel.find(x => x.contact_id == cellValues.row.responsible_id)
          return un.name;
        } else {
          return null
        }
      }
    },
    {
      field: "approved", headerName: "Status", flex: 0.3,
      renderCell: (cellValues) => {
        if (cellValues.row.approved) {
          return <Tooltip title="Product approved"><IconButton ><CheckIcon style={{ color: "green" }} /></IconButton></Tooltip>
        }
        else {
          return <Tooltip title="Product not approved"><IconButton ><WarningIcon style={{ color: "red" }} /></IconButton></Tooltip>
        }
      }
    },
    {
      field: "",
      headerName: "Action",
      minWidth: 250,
      renderCell: (cellValues) => {
        if (edit && cellValues.row.responsible_id == props.context.auth.contact && !props.isDealApproved) {

          if (cellValues.row.approved) {
            return (
              <div>
                <ModuleButton
                  btnIcon="visibility"
                  style={{ backgroundColor: "blue", color: "white" }}
                  onClick={(event) => props.handleLinkClick(event, cellValues.row.id, "product")}
                  tip="View"
                />

                <ModuleButton
                  btnIcon="deselect"
                  style={{
                    background: "red",
                    color: "white",
                    marginLeft: "10px"
                  }}
                  onClick={(event) => handleProductCancel(cellValues.row)}
                  tip="unapprove"
                />
              </div>
            )
          }

          else{
          return (
            <div>
              <ModuleButton
                btnIcon="edit"
                style={{ backgroundColor: "orange", color: "white" }}
                onClick={(event) => props.handleLinkClick(event, cellValues.row.id, "product")}
                tip="View"
              />

              <ModuleButton
                btnIcon="playlist_add_check"
                style={{
                  background: "#50C878",
                  color: "white",
                  marginLeft: "10px"
                }}
                onClick={() => handleApproveProduct(cellValues.row)}
                tip="Approve"
              />
            </div>

          )}
        }

        else{
          return (
            <div>
              <ModuleButton
                btnIcon="visibility"
                style={{ backgroundColor: "blue", color: "white" }}
                onClick={(event) => props.handleLinkClick(event, cellValues.row.id, "product")}
                tip="View"
              />
            </div>
          )
        }

      },
    }
  ];

  const options = {
    page: page,
    handlePageChange: handlePageChange,
    pageSize: pageSize,
    handleSizeChange: handleSizeChange,
    rowsPerPageOptions: [10, 25, 100],
    rowCount: rowCount,
    autoHeight: true,
    height: 300
  };


  return (

    <div className="block">
      <div className="container-fluid">
        <div style={{ width: '100%' }}>
        <h4>Products</h4>
          <br></br>
          <div style={{ marginTop: "10px" }}></div>

          <DataTable
            columns={columns}
            data={props.data}
            pagination={pagination}
            options={options}
            allowDelete={props.type != 'view' ? CanDelete("deal-builder") : false}
            handleDeleteArray={false}
            searchText={searchText}
            setSearchText={setSearchText}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
            checkboxSelection
          />

          <ApiLoaderComponent
            status={loading.status}
            data={loading.data}
          />

        </div>
      </div>
    </div>
  )
}

export default ProductConfigTable;