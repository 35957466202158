import React, { useState, useEffect } from "react";
import { AppHeaderComponent, StorageManager, TitleControl } from "@unity/components";
import { CanCreate, CanModuleAdmin } from "../../services/Permissions";
import ModuleFunctions from "../../modules/ModuleFunctions";
import ModuleTabs from "../../common/ModuleTabs";
import ActiveConIndex from "../../modules/deal-config/ActiveConIndex";
import WorkingIndex from "../../modules/deal-config/WorkingIndex";
import ArchiveConIndex from "../../modules/deal-config/ArchiveConIndex";
import { Tooltip } from "@mui/material";
import NewModuleTabs from "../../common/NewModuleTabs";

const MAXTAB = 2;
const MINTAB = 0;

export default function Deals(props) {
    const [tab, setTab] = useState(0);
    const [bus, setBus] = useState(false);

    const sm = new StorageManager()
    const tc = new TitleControl(); 

    const create = CanCreate("deal-config");
    const admin = CanModuleAdmin("deal-config");

    const setTitle = () => {
        tc.setControlTitle((
            <div style={{ display: "flex", alignItems: "center",  gap: "1vw" }}>
            </div>
        ), "DEAL CONFIG")
      }

    const busChange = (data) => {
        setBus(data);
    };

    const handleChange = async (event, newValue) => {
        setTab(newValue);
    };

    const tabs = [
        { icon: "update", label: "Work List" },
        { icon: "build", label: "active" },
        { icon: "archive", label: "archived" },
    ];


    useEffect(() => {
        setTitle()
        ModuleFunctions.getBusinesses({ change: busChange });
        let t = parseInt( sm.getItem( "last-viewed-deal-config-tab" , 0 ) )
        if( isNaN(t) ) t = 0;
    
        if( t >= MINTAB && t <= MAXTAB || t != NaN ) setTab(t);
        
      }, []);
    
      if(sm.checkGrant()){
        useEffect(() => {
            sm.setItem("last-viewed-deal-config-tab", tab);
        }, [tab]);
    }


    return (
        <>
            {/* <AppHeaderComponent
                context={props.context}
                theme={props.context.theme}
                name={"deal-config"}
                spacer={true}
            /> */}

            <NewModuleTabs tabs={tabs} value={tab} setValue={setTab} handleChange={handleChange} />
            <div style={{ paddingTop: 86 }}>
                {tab === 0 &&
                    <WorkingIndex
                        context={props.context}
                        bus={bus}
                    />}
                {tab === 1 &&
                    <ActiveConIndex
                        context={props.context}
                        bus={bus}
                    />}
                {tab === 2 &&
                    <ArchiveConIndex
                        context={props.context}
                        bus={bus}
                    />}


            </div>
        </>
    );
}
