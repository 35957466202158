import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { DealBuilderApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import { CanCreate, CanRead, CanReadAll, CanUpdate, CanModuleAdmin, CanDelete } from "../../services/Permissions";
import ModuleFunctions from "../../modules/ModuleFunctions";
import DealOverview from "../../modules/DealOverview";
import PersonDisplayTable from "../../modules/PersonDisplayTable";
import ProductConfigTable from "../../modules/deal-config/ProductConfigTable";
import DisplayInstructionsDialogBox from "../../modules/DisplayInstructionsDialogBox";

export default function ConfigView(props) {
  const [loading, setLoading] = useState({ status: false });
  const [fetchTrigger, setFetchTrigger] = useState(0);// This is used to determine if page needs to be re-rendered. The value doesn't matter, just the change!
  const [data, setData] = useState(false);
  const [errors, setErrors] = useState(false);
  const [personnel, setPersonnel] = useState(false);
  const [structure, setStructure] = useState(false);
  const [periods, setPeriods] = useState(false);
  const [charges, setCharges] = useState(false);
  const [methods, setMethods] = useState(false);
  const [vats, setVats] = useState(false);
  const [bus, setBus] = useState(false);
  const [salesOpp, setSalesOpp] = useState(false);

  const [productSelectionDialogBox, setProductSelectionDialogBox] = useState(false);
  const [instructionsDialogBox, setInstructionsDialogBox] = useState(false)
  const [instructions, setIntructions] = useState(false)

  let history = useHistory();

  const create = CanCreate("deal-config");
  const read = CanRead("deal-config");
  const edit = CanUpdate("deal-config");
  const admin = CanModuleAdmin("deal-config");

  const dealChange = (data) => {
    setData(prevState => ({
      ...prevState,
      ...data
    }));
  };

  const closeInstructionsDialogBox = () => {
    setInstructionsDialogBox(false)
    setIntructions(false)
  }

  const openInstructionsDialogBox = (data) => {
    setIntructions(data.instructions)
    setInstructionsDialogBox(true)
  }

  const handleProductSelectionDialogBox = () => {
    productSelectionDialogBox ?
      setProductSelectionDialogBox(false) :
      setProductSelectionDialogBox(true)
  }

  const openPersonSelectionDialogBox = (data) => {
    setEditTeamMemberState(false)
    setTeamMember(data)
    setPersonAssignmentDialogBox(true);
  }

  const closePersonSelectionDialogBox = () => {
    setLevelName(false)
    setEditTeamMemberState(false)
    setPersonAssignmentDialogBox(false);
    setTeamMember(false)
  }

  const editPersonSelectionDialogBox = (data) => {
    setLevelName(data.level_name)
    setEditTeamMemberState(true)
    setTeamMember(data)
    setPersonAssignmentDialogBox(true);
  }

  const handleLinkClick = (event, id, path) => {
    event.preventDefault();
    history.push({
      pathname: `/deal-config/${path}/${id}`
    });
  };

  const triggerChange = () => {
    setFetchTrigger(fetchTrigger + 1);
  };

  const personnelChange = (data) => {
    setPersonnel(data);
  };

  const structureChange = (data) => {
    setStructure(data);
  };

  const periodsChange = (data) => {
    setPeriods(data);
  };

  const vatsChange = (data) => {
    setVats(data);
  };

  const chargesChange = (data) => {
    setCharges(data);
  };

  const methodsChange = (data) => {
    setMethods(data);
  };

  const busChange = (data) => {
    setBus(data);
  };

  const salesOppChange = (data) => {
    setSalesOpp(data)
  }

  const handleDealSave = async () => {
    setLoading({ status: true, data: "Updating your Deal, Please Wait...." });

    const res = await DealBuilderApi.updateDeal(props.route.match.params.id, data);

    if (res.success) {
      dealChange({ changed: false });
      setLoading({ status: false });
    } else {
      if (res.errors) {
        setErrors(res.errors);
        setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
        setTimeout(() => {
          setLoading({ status: false });
        }, 3000);
      } else {
        setLoading({ status: true, data: res.message });
        setTimeout(() => {
          history.push("/deal-config/index");
          setLoading({ status: false });
        }, 3000);
      }
    }
  };


  useEffect(() => {
    ModuleFunctions.getPersonnel({ personnelChange: personnelChange });
    ModuleFunctions.getPeriodicCodes({ periodsChange: periodsChange });
    ModuleFunctions.getChargeCodes({ chargesChange: chargesChange });
    ModuleFunctions.getPaymentMethods({ methodsChange: methodsChange });
    ModuleFunctions.getVatRates({ vatsChange: vatsChange });
    ModuleFunctions.getBusinesses({ change: busChange });
    ModuleFunctions.getSalesOpps({ salesOppChange: salesOppChange });
    ModuleFunctions.getDeal({ id: props.route.match.params.id, dealChange: dealChange });
    ModuleFunctions.getStructure({ structureChange: structureChange });
  }, [fetchTrigger]);



  if (edit && data && structure && personnel) {
    return (
      <>
        <AppHeaderComponent
          saveBtn={data.changed ? handleDealSave : null}
          context={props.context}
          theme={props.context.theme}
          name="deal-config"
          subpage="edit"
          spacer={true}
        />

        <DealOverview
          context={props.context}
          isDealConfig={true}
          type='view'
          create={create}
          read={read}
          edit={edit}
          data={data}
          errors={errors}
          personnel={personnel}
          structure={structure}
          periods={periods}
          charges={charges}
          methods={methods}
          vats={vats}
          bus={bus}
          salesOpp={salesOpp}
          dealChange={dealChange}
        />

        <DisplayInstructionsDialogBox
          open={instructionsDialogBox}
          close={closeInstructionsDialogBox}
          instructions={instructions}
        />

        <PersonDisplayTable
          data={data.team_members}
          deal_id={props.route.match.params.id}
          openDialog={openPersonSelectionDialogBox}
          personnel={personnel}
          edit={edit}
          triggerChange={triggerChange}
          editPersonSelectionDialogBox={editPersonSelectionDialogBox}
          type="view"
          openInstructionsDialogBox={openInstructionsDialogBox}
        />

        <ProductConfigTable
          deal_id={props.route.match.params.id}
          data={data.live_products}
          handleLinkClick={handleLinkClick}
          handleProductSelectionDialogBox={handleProductSelectionDialogBox}
          bus={bus}
          personnel={personnel}
          triggerChange={triggerChange}
          context={props.context}
          isDealApproved={data.approved}
       />

        <ApiLoaderComponent
          status={loading.status}
          data={loading.data}
        />
      </>
    );
  } else {
    return <LoadingComponent color={props.context.theme.sidebar.background}/>;
  }
}