import React, { useState, useEffect } from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ModuleButton from "../common/ModuleButton";
import { DealBuilderApi, AppHeaderComponent, LoadingComponent, ApiLoaderComponent } from "@unity/components";
import "./Stylesheet.css"


const RequestChangeDialogBox = (props) => {

    const [requestData, setRequestData] = useState(false);
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleRequestLog = (e) => {
        const tempData = { ...props.data }
        tempData[e.target.name] = e.target.value
        setRequestData(tempData)
    }  


    const handleChangeRequestSave = async () => {

        setLoading({ status: true, data: "Sending request, Please Wait...." });
        const obj = { id: parseInt(props.data.id), ...requestData };
        const res = await DealBuilderApi.liveProductRequestChange(props.data.id, requestData);

            if (res.success) {
                setTimeout(() => {
                    props.triggerChange()
                    props.close()
                    setLoading({ status: false });
                }, 3000);
            } else {
                if (res.errors) {
                    setErrors(res.errors);
                    setLoading({ status: true, data: "Validation Errors!, Please Wait...." });
                    setTimeout(() => {
                        setLoading({ status: false });
                    }, 3000);
                } else {
                    setLoading({ status: true, data: res.message });
                    setTimeout(() => {
                        props.triggerChange()
                        props.close()
                        setLoading({ status: false });
                    }, 3000);
                }
            }
    };


    return (
        <>
        <Dialog open={props.open} onClose={props.close} fullWidth={true} maxWidth={'sm'}>
            <DialogTitle style={{ backgroundColor:"darkBlue" }}><h4 style={{ color:"white" }}>Change Request</h4></DialogTitle>
            <DialogContent>
                <DialogContentText>

                    <textarea
                    label="reason"
                    name="reason"
                    style={{ width:"100%" , marginTop:"20px" , height:"400px", resize:"none"}}
                    className="requestField"
                    placeholder="Enter your request"
                    onChange={ (e) => handleRequestLog(e)}
                    >
                        
                    </textarea>

                </DialogContentText>
            </DialogContent>
            <DialogActions>
                    <ModuleButton
                        btnIcon="check"
                        text="Submit"
                        style={{
                            background: "blue",
                            color: "white",
                            marginRight: 10
                        }}
                        tip="Save"
                        //onClick={() => props.assignmentCancel(dialogData)}
                        onClick={() => handleChangeRequestSave()}
                    /> 
            </DialogActions>
        </Dialog>

        
        <ApiLoaderComponent
          status={loading.status}
          data={loading.data}
        />
    
        </>
    )
}

export default RequestChangeDialogBox